<template>
  <div>
    <Toolbar></Toolbar>
    <v-sheet class="pt-5" color="greyPrimary">
      <v-container class="text-center">
        <h2 class="text-sm-h4 font-weight-medium">Términos del servicio</h2>
        <p class="text-body-2">Última actualización el 1 de enero de 2022</p>
      </v-container>
    </v-sheet>
    <v-container class="pt-10">
      <div>
        <div class="px-5 pb-10">
          <p class="text-subtitle-2">Nuestro contacto electrónico es infoseoferta@gmail.com </p>
          <p>Al marcar la opción "Acepto los Términos de Servicio" usted está de acuerdo en aceptar los siguientes
            términos y condiciones ("Términos de servicio"). Cualquier nueva característica o herramienta que pueda
            añadirse al actual servicio estarán también sujetas a los términos y condiciones de uso.</p>
          <p>Utilizando el servicio, usted ha leído estos términos y condiciones y está de acuerdo con ellos. Si usted
            no está de acuerdo con todos los términos y condiciones, por favor, no utilice el servicio.</p>
          <p>SeOferta, se reserva el derecho a actualizar y cambiar los términos de servicio mediante la publicación de
            actualizaciones y cambios en el servicio web. Se aconseja que compruebe los términos y condiciones de
            servicio regularmente por si alguna actualización le pueda afectar.</p>
          <v-expansion-panels class="mt-5" accordion flat>
            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Descripción del Servicio
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>SeOferta, a través de su plataforma, presta un servicio en el cual los usuarios pueden crear tiendas
                  virtuales dentro del portal http://www.seoferta.net/ para poder vender sus productos o servicios.</p>

                <p>Usted debe saber que cuando crea una tienda virtual dentro del sistema SeOferta, usted es responsable
                  del funcionamiento de la tienda virtual que ha creado, así como de los datos que ha introducido en la
                  tienda virtual y de las operaciones de compraventa que se realicen en la tienda virtual.</p>

                <p>SeOferta declina toda responsabilidad del uso que los vendedores hacen de las tiendas virtuales que
                  crean dentro del sistema.</p>
                <p>SeOferta no interviene de ningún modo en la conclusión de las operaciones de compraventa entre los
                  vendedores y los compradores del Servicio y, en particular, no está involucrado en los reembolsos o
                  garantías que puedan haber sido ofrecidas. Así, cuando se produce una venta, usted y la otra parte
                  (compradores) son los únicos responsables de que ésta se lleve a cabo.</p>
                <p>SeOferta no tiene facultades para actuar ni en nombre del vendedor ni en nombre del comprador. Dado
                  que la transacción únicamente tiene lugar entre los vendedores y compradores, SeOferta no está
                  implicada en las disputas que puedan surgir entre un comprador y un vendedor, ni en su resolución.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Modificaciones al Servicio y Costo
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!--                <p>SeOferta se reserva el derecho de modificar, suspender o interrumpir el servicio con o sin previo-->
                <!--                  aviso en cualquier momento y sin ninguna responsabilidad hacia usted. SeOferta se reserva el derecho-->
                <!--                  de cobrar por el servicio que presta en su portal http://www.seoferta.net/. </p>-->
                <!--                <p>Los precios para la utilización de los servicios de SeOferta están sujetos a cambios y serán-->
                <!--                  notificados por correo electrónico a los vendedores y en el portal http://www.seoferta.net/ con 30-->
                <!--                  días de antelación.</p>-->
                <p>SeOferta se reserva el derecho de modificar, suspender o interrumpir el servicio con o sin previo
                  aviso en cualquier momento y sin ninguna responsabilidad hacia usted.</p>
                <p>Los servicios que ofrece actualmente SeOferta son totalmente GRATIS; cualquier cambio sera
                  notificado por correo electrónico a los vendedores y en el portal http://www.seoferta.net/ con 30
                  días de antelación. </p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">-->
            <!--              <v-expansion-panel-header class="font-weight-medium">-->
            <!--                Formas de pago-->
            <!--                <template v-slot:actions>-->
            <!--                  <v-icon color="primary">-->
            <!--                    $expand-->
            <!--                  </v-icon>-->
            <!--                </template>-->
            <!--              </v-expansion-panel-header>-->
            <!--              <v-expansion-panel-content>-->
            <!--                <p>Los servicios que ofrece actualmente SeOferta son totalmente gratis.</p>-->
            <!--              </v-expansion-panel-content>-->
            <!--            </v-expansion-panel>-->

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Usuarios del Servicio
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Usted debe ser mayor de edad (18 años) para utilizar este servicio. Usted debe proporcionar una
                  información real y actual de sus datos personales, dirección y cualquier otra información que pueda
                  ser necesaria como parte del proceso de registro y/o el uso continuado del Servicio. Usted es
                  responsable de mantener la confidencialidad de su contraseña y de la información de su cuenta, y es
                  responsable de todas las actividades que se producen al respecto. SeOferta no puede y no será
                  responsable por cualquier pérdida o daño en su fracaso de mantener la seguridad de su cuenta y
                  contraseña. SeOferta se reserva el derecho de rechazar a prestar el servicio a cualquier persona y en
                  cualquier momento, sin previo aviso.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Representaciones y garantías
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Usted manifiesta y garantiza que toda la información que ha proporcionado a SeOferta es correcta y
                  actual, y que usted tiene todo el derecho, poder y la autoridad para aceptar este Acuerdo y llevar a
                  cabo los actos que a continuación se especifican.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Uso apropiado
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="mb-1">Usted se compromete a utilizar el servicio prestado por SeOferta, en cumplimiento con
                  todas las leyes
                  locales, estatales, nacionales, internacionales, incluyendo las leyes relativas a la transmisión de
                  datos personales de su país de residencia. Usted está obligado a cumplir las siguientes reglas para la
                  utilización del Servicio prestado por SeOferta: </p>
                <v-list color="greyPrimary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      No puede utilizar el Servicio para transmitir o distribuir cualquier contenido o producto que
                      sea ilegal, difamatorio, acosador, abusivo, fraudulento u obsceno.
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      No puede utilizar el Servicio para insertar, transmitir o distribuir contenido o productos que
                      infrinjan los derechos de propiedad intelectual
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      No puede utilizar el Servicio para cualquier uso fraudulento o inapropiado.
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <p>La violación de cualquier punto de los anteriores puede dar como resultado la resolución inmediata
                  del contrato. SeOferta se reserva el derecho, pero no tiene ninguna obligación, de investigar su uso
                  del Servicio, a fin de determinar si una violación del Acuerdo de Términos y Condiciones del Servicio
                  se ha producido para incumplir cualquier ley.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Modificaciones del producto
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                SeOferta dispone de total libertad para modificar las tiendas o administraciones de éstas para incluir
                mejoras, actualizaciones o nuevas funcionalidades.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Condiciones Generales
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>SeOferta no garantiza que el servicio será ininterrumpido, puntual, seguro, o libre de errores.</p>
                <p>Usted expresamente entiende y acepta que SeOferta no será responsable de ningún daño directo,
                  indirecto, accidental o especial, que tenga como consecuencia daños y perjuicios, incluyendo daños por
                  pérdida de beneficios, datos u otras pérdidas intangibles resultantes del mal uso o de la incapacidad
                  para utilizar el Servicio.</p>
                <p>En ningún caso SeOferta o nuestros proveedores serán responsables por la pérdida de beneficios o de
                  ningún daño derivado de la conexión con nuestro sitio web.</p>
                <p>Usted se compromete a no reproducir, duplicar, copiar, vender, revender o explotar cualquier porción
                  del Servicio, uso del Servicio, o acceso al Servicio sin el permiso expreso y por escrito de
                  SeOferta.</p>
                <p>Es posible, aunque SeOferta no tiene la obligación, de retirar cualquier Contenido y Cuentas con
                  contenido que consideremos, según nuestro criterio, delictivo, ofensivo, amenazante, calumnioso,
                  difamatorio, pornográfico, obsceno o de dudosa reputación o que viole cualquiera de las partes de la
                  propiedad intelectual o estos Términos y Condiciones de este Servicio.</p>
                <p>SeOferta entiende que la privacidad es importante para usted. Sin embargo, usted está de acuerdo en
                  que SeOferta tiene derecho en todo momento de suprimir o rechazar la distribución de cualquier
                  contenido en el Servicio, como contenidos que infrinjan los términos de este Acuerdo. También se
                  reserva el derecho de acceder, leer, preservar y revelar cualquier tipo de información que
                  razonablemente crea necesaria para cumplir cualquier ley, regulación, proceso legal o solicitud
                  gubernamental.</p>
                <p>Garantizar el cumplimiento de este Acuerdo, incluyendo la investigación de posibles violaciones,
                  detectar, prevenir cualquier modo de fraude, seguridad o temas técnicos.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Derechos de Propiedad Intelectual de SeOferta
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Usted reconoce que SeOferta posee todos los derechos, títulos e intereses sobre y para el Servicio,
                incluyendo, sin limitación, todos los derechos de propiedad intelectual. En consecuencia, acepta no
                copiar, reproducir, alterar, modificar ni crear trabajos derivados del Servicio prestado por SeOferta.
                También acepta que no va a utilizar robots, arañas, cualquier otro tipo de software automatizado o
                procesos manuales para controlar o copiar cualquier contenido del Servicio. Los derechos de SeOferta no
                incluyen el contenido de terceros usados como parte del Servicio, incluyendo el contenido de las
                comunicaciones que aparecen en el Servicio.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">
              <v-expansion-panel-header class="font-weight-medium">
                Modificaciones del producto
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                SeOferta dispone de total libertad para modificar las tiendas o administraciones de éstas para incluir
                mejoras, actualizaciones o nuevas funcionalidades.
              </v-expansion-panel-content>
            </v-expansion-panel>


            <!--            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">-->
            <!--              <v-expansion-panel-header class="font-weight-medium">-->
            <!--                Cambio de Plan, Cancelación y Terminación-->
            <!--                <template v-slot:actions>-->
            <!--                  <v-icon color="primary">-->
            <!--                    $expand-->
            <!--                  </v-icon>-->
            <!--                </template>-->
            <!--              </v-expansion-panel-header>-->
            <!--              <v-expansion-panel-content>-->
            <!--                <p>Usted puede cambiar el plan, cancelar el uso de los Servicios y/o rescindir el presente Acuerdo, con-->
            <!--                  o sin causa en cualquier momento notificándolo a SeOferta por escrito, vía email 15 días antes del-->
            <!--                  vencimiento de la mensualidad. Todas las solicitudes recibidas posteriormente se cambiarán o darán de-->
            <!--                  baja el mes siguiente por razones administrativas. </p>-->
            <!--                <p>SeOferta podrá en cualquier momento y por cualquier motivo, dar por finalizados los Servicios,-->
            <!--                  denunciar el presente Acuerdo, o suspender o cancelar su cuenta. En el caso de cancelación, su cuenta-->
            <!--                  se desactivará y no podrá concederse el acceso a su cuenta o a los archivos o cualquier otro tipo de-->
            <!--                  contenido que figura en su cuenta, aunque la copia residual de la información puede permanecer en-->
            <!--                  nuestro sistema.</p>-->
            <!--              </v-expansion-panel-content>-->
            <!--            </v-expansion-panel>-->

            <!--            <v-expansion-panel class="mb-2 rounded" style="background-color: #f5f5f7">-->
            <!--              <v-expansion-panel-header class="font-weight-medium">-->
            <!--                Fraude e impagos-->
            <!--                <template v-slot:actions>-->
            <!--                  <v-icon color="primary">-->
            <!--                    $expand-->
            <!--                  </v-icon>-->
            <!--                </template>-->
            <!--              </v-expansion-panel-header>-->
            <!--              <v-expansion-panel-content>-->
            <!--                <p>Sin perjuicio de cualquier otro recurso, SeOferta podrá suspender o cancelar su cuenta si sospechamos-->
            <!--                  que usted ha participado o está participando en una actividad fraudulenta en el sitio web de-->
            <!--                  SeOferta.</p>-->
            <!--                <p>SeOferta se reserva el derecho de suspender el servicio sin previo aviso a aquellos clientes que no-->
            <!--                  abonen, a su debido tiempo, su cuota periódica por el uso del servicio. </p>-->
            <!--              </v-expansion-panel-content>-->
            <!--            </v-expansion-panel>-->
          </v-expansion-panels>

        </div>
      </div>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>

const Toolbar = () => import(/* webpackPrefetch: true */ "../components/Toolbar.vue");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

export default {
  name: "Terms",
  components: {
    Toolbar,
    Footer
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}
</style>
