<template>
  <div>
    <Toolbar></Toolbar>
    <v-sheet class="py-5" color="greyPrimary">
      <v-container class="text-center">
        <h2 class="text-sm-h4 font-weight-medium">Política de privacidad</h2>
        <p class="text-body-2">Última actualización el 1 de enero de 2022</p>
      </v-container>
    </v-sheet>
    <v-container>
      <div class="px-5 pb-10">
        <p>&nbsp;</p>
        <p>El presente Política de Privacidad establece los términos en que SeOferta usa y protege la información que es
          proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la
          seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual
          usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este
          documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le
          recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos
          cambios.</p>
        <p><strong>Información que es recogida</strong></p>
        <p>Nuestro sitio web podrá recoger información personal por ejemplo: Nombre,&nbsp; información de contacto como&nbsp;
          su número de teléfono e información demográfica. Así mismo cuando sea necesario podrá ser requerida
          información específica para procesar algún pedido o realizar una entrega o facturación.</p>
        <p><strong>Uso de la información recogida</strong></p>
        <p>Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente
          para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios.
          &nbsp;</p>
        <p>SeOferta está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos
          los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no
          autorizado.</p>
        <p><strong>Cookies</strong></p>
        <p>Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta
          información es empleada únicamente para análisis estadístico y después la información se elimina de forma
          permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies
          ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni
          de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso
          de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor
          servicio web.</p>
        <p><strong>Enlaces a Terceros</strong></p>
        <p>Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de
          clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por
          lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros
          sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable
          que los consulte para confirmar que usted está de acuerdo con estas.</p>

        <p>Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su
          consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
        <p>SeOferta se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier
          momento.</p>
      </div>

    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>

const Toolbar = () => import(/* webpackPrefetch: true */ "../components/Toolbar.vue");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

export default {
  name: "Policy",
  components: {
    Toolbar,
    Footer
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}
</style>

